@import url(https://fonts.googleapis.com/css?family=Barlow&display=swap);
body {
  font-size: 12px;
  font-family: 'Barlow', sans-serif;
  background-color: rgb(49, 116, 55);
}

h1 {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0 0 .25em 0;
}

h2 {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 0 .25em 0;
}

h3 {
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0 0 .25em 0;
}

h4 {
  font-size: 1.1rem;
  margin: 0 0 .25em 0;
}

.center {
  margin: 0 auto;
}

.main {
  margin: 1em auto;
  padding: 1em;
  border-radius: 1em;
  box-shadow: .25em .25em .25em rgba(0, 0, 0, .25);
  max-width: 80vw;
  background-color: whitesmoke
}

.column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.column > * {
  flex-basis: fit-content;
}

.styles_login__3XvrT {
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

